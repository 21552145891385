import React, { useEffect, useState,useRef } from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, StackDivider, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, SimpleGrid, FormControl, FormLabel, Center, Spinner, Select, Image, Avatar,RadioGroup ,Radio,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { PATROLLING_LOGS_CLEAR_SNACKBAR,PATROLLING_LOGS_SEARCH } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';
import MissedLogsTableRow from './MissedLogsTableRow'
// import DevicesPagination from './DevicesPagination';
// import PatrollingLogsagination from './PatrollingSessionPagination'

import { getMissedLogs } from '../../../actions/patrollingLogsAction';
// import PatrollingSessionPagination from './PatrollingSessionPagination';
// import PatrollingSessionAdvanceSearch from './PatrollingSessionAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import TimelineRow from 'components/Tables/TimelineRow';
import debounce from 'lodash.debounce';
const MissedLogsTable = (props) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const {url= "",plant} = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.patrollingLogs.status);
  const responseMessage = useSelector(state => state.patrollingLogs.text);
  const loading = useSelector(state => state.patrollingLogs.loading);
  const patrollingLogsList = useSelector(state => state.patrollingLogs.patrollingLogsList);
  const response = useSelector(state => state.patrollingLogs.response);
  const showSnackbar = useSelector(state => state.patrollingLogs.showSnackbar);
  const currentPage = useSelector(state => state.patrollingLogs.page);
  const limit = useSelector(state => state.patrollingLogs.limit);
  const totalItems = useSelector(state => state.patrollingLogs.totalItems);
  const searching = useSelector(state => state.patrollingLogs.searching);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [getPatrollings, setGetPetrollings] = useState([]);
  const [patrollingDetails, setPatrollingDetails] = useState([]);
  const [allGuards, setAllGuards] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [guard, setGuard] = useState("");
  const history = useHistory();
  const tableRef = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [timeLineLoading, setTimeLineLoading] = useState(false);

  // lazy loading
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [newMissedLogs, SetNewMissedLogs] = useState([]);
  const [startAppending, setStartAppending] = useState(false);
  const authDetail = useSelector(state => state.auth.authDetail);
  useEffect(() => {
    // dispatch({
    //   type: PATROLLING_LOGS_SEARCH,
    //   payload: {
    //     searching: "",
    //   },
    // });
    dispatch(getMissedLogs(`&plant=${plant}&page=${page}&limit=3`));
  }, [dispatch]);
 
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: PATROLLING_LOGS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
      getAllGuards();
  }, [])

  useEffect(() => {
    // Function to update the state with the current window height
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Add event listener to update the screen height whenever the window is resized
    window.addEventListener('resize', updateScreenHeight);

    // Remove event listener when component unmounts to prevent memory leaks
    return () => window.removeEventListener('resize', updateScreenHeight);
  }, []);

  const getPetrolling = (startDate="",endDate="",guard="") => {
    setPageLoading(true)
    setPatrollingDetails([]);
    axios
      .get(`${server_url}/patrolling-Duration/new?guardId=${guard}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setGetPetrollings(response.data.data)
        setPageLoading(false)
      })
      .catch((e) => {
       console.log("error")

      });
      
  }
  
  const getPetrollingDurationDetails = (id) => {
    setTimeLineLoading(true);
    axios
      .get(`${server_url}/patrolling-Duration/details/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setPatrollingDetails(response.data.data)
        setTimeLineLoading(false);
      })
      .catch((e) => {
       console.log("error")

      });
  }

  const getAllGuards = () => {
    axios
      .get(`${server_url}/guard`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        setAllGuards(response.data.data);
        if(response.data.data)
        {
          getPetrolling("","","");
        }
      })
      .catch((e) => {
       console.log("error")

      });
  }
  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(deviceGetAll(sorting));
  };

  // useEffect(() => {
  //   const searching = "&search=" + search;
  //   dispatch({
  //     type: PATROLLING_LOGS_SEARCH,
  //     payload: {
  //       searching: "",
  //     },
  //   });
  //   dispatch(getMissedLogs(searching));
  // }, [search]);
  const handelSearch = (search) => {
    setPage(1);
    const searching = "&search=" + search+"&limit=3&page="+page;
    dispatch({
      type: PATROLLING_LOGS_SEARCH,
      payload: {
        searching: "",
      },
    });
    dispatch(getMissedLogs(searching));
    SetNewMissedLogs([]);
    
  }
console.log(patrollingDetails.length);
console.log("patrollingDetails->>>>>>>>>>>");
const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // padStart ensures two digits
  let day = currentDate.getDate().toString().padStart(2, '0'); // padStart ensures two digits
  return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
};

// lazy loading 
const handleScroll = debounce((e) => {
    
  const bottomReached = (e.target.scrollHeight - e.target.scrollTop <= (parseInt(e.target.clientHeight) + 1)) &&  (e.target.scrollHeight - e.target.scrollTop >= (e.target.clientHeight-1));
  if(!loading)
    {
      console.log("->>>>> this bottom ->>>>>>" + bottomReached);
        console.log("->>>>> this ->>>>>>" + (e.target.clientHeight + 1) );
        console.log("->>>>> this ->>>>>>" + (e.target.clientHeight - 1) );
        console.log("->>>>> this ->>>>>>" + e.target.scrollHeight );
        console.log("->>>>> this ->>>>>>" + e.target.scrollTop );
        console.log("->>>>> match ->>>>>>" + (e.target.scrollHeight-e.target.scrollTop) );
      if (bottomReached && !isFetching && newMissedLogs.length < totalItems && patrollingLogsList.length != 0) {
        console.log("Hist This");
        setPage(prevPage => prevPage + 1); // Load next page
        setIsFetching(true); // Prevent multiple fetches at the same time
      }
    }
}, 300); // 300ms debounce time;

useEffect(() => {
  if (isFetching) {
    dispatch(getMissedLogs(`&page=${page}&limit=3${searching}&search=${search}`));
    setIsFetching(false);
  }
}, [isFetching,page])
useEffect(() => {
  if(startAppending)
      {
          addMissedDataArray(patrollingLogsList);
          console.log("Mark here");
      }
  setStartAppending(true);
}, [patrollingLogsList])
useEffect(() => {
  SetNewMissedLogs([]);
  setPage(1);
}, [searching])
// Function to add new data
const addMissedDataArray = (newDataArray) => {
SetNewMissedLogs((prevData) => [...prevData,...newDataArray]);
};
  return (
    <>
    {/* <PatrollingSessionAdvanceSearch></PatrollingSessionAdvanceSearch> */}
    <Flex direction="column" pt={{ base: "120px", md: "2px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Missed Locations
             {/* <span style={{
                color:"red"
              }}>({loading ? '':totalItems})</span> */}
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-logs-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <DownloadTableExcel
              filename="Patrolling Logs Table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <Button colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            </DownloadTableExcel>
          </Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    
                      if (e.target.value.length < 1) {
                        setSearch("");
                        handelSearch("");
                      }
                      else if (e.target.value.length >= 3)
                      {
                        setSearch(e.target.value);
                        handelSearch(e.target.value);
                      }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div
        onScroll={handleScroll}
        className='vertical-scroll'>
        
          {/* {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>: */}
                  <Table variant="simple" color={textColor} ref={tableRef}>
            <Thead className='stick-header'>
              <Tr my=".8rem" pl="0px" color="gray.400">
              {/* <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th> */}
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Date
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Missed By
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              
                
                            
                
                            
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Location
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                            
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Sub Location
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>

                
              </Tr>
            </Thead>
            <Tbody>
              { newMissedLogs ? (newMissedLogs.map((row, index, arr) => {
                  return (
                    <MissedLogsTableRow
                     serialnumber={((currentPage - 1)*limit) + (index+1)}
                      date={row.date}
                      time = {row.time}
                      guardName={row.missedBy}
                      
                      location = {row.routeName}
                      sublocation = {row.floorName}
                      photo = {row.image}
                      
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      key={row._id}
                    />
                  );
              })):(<h1></h1>)
              }
            </Tbody>
          </Table>
          {loading ? <Center><Spinner mt="24px" size="lg" /></Center>:<></>}
          {/* } */}
          </div>
          {/* {patrollingLogsList.length === 0 ? (
            <></>
          ) : (
            <PatrollingSessionPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(getMissedLogs(`&page=${selectedPage}`));
              }}
            />
          )} */}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default MissedLogsTable;
