const {
    INSPECTION_SUMMARY_LOADING,
    INSPECTION_SUMMARY_GET_ALL_SUCCESS,
    INSPECTION_SUMMARY_ERROR,
    INSPECTION_SUMMARY_CLEAR_SNACKBAR,
    INSPECTION_SUMMARY_SEARCH,
    INSPECTION_SUMMARY_CLEAR
  } = require('../constants/types');
  
  const initialState = {
    response: {},
    inspectionList: [],
    inspectionListDetail: {},
    loading: false,
    status: 'success',
    text: '',
    showSnackbar: false,
    limit : 0,
    page : 0,
    totalPages : 0,
    totalItems : 0,
    searching : ""
  };
  
  const inspectionSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
      case INSPECTION_SUMMARY_SEARCH:
        return {
          ...state,
          searching: action.payload.searching
        };
      case INSPECTION_SUMMARY_CLEAR_SNACKBAR:
        return {
          ...state,
          showSnackbar: false
        };
      case INSPECTION_SUMMARY_LOADING:
        return {
          ...state,
          loading: true
        };
      case INSPECTION_SUMMARY_ERROR:
        return {
          ...state,
          inspectionList: [],
          response: {},
          inspectionListDetail: {},
          limit : 0,
          page : 0,
          totalPages : 0,
          totalItems : 0,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case INSPECTION_SUMMARY_GET_ALL_SUCCESS:
        return {
          ...state,
          inspectionList: action.payload.inspectionList,
          response: action.payload.response,
          inspectionListDetail: action.payload.inspectionListDetail,
          limit: action.payload.limit,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
          loading: false,
          status: action.payload.status,
          text: action.payload.text,
          showSnackbar: true
        };
      case INSPECTION_SUMMARY_CLEAR:
        return {
            ...state,
            inspectionList: action.payload.inspectionList, // This will reset to an empty array
            loading: action.payload.loading,
            status: action.payload.status,
            text: action.payload.text
        };
      default:
        return state || {};
    }
  };
  
  export default inspectionSummaryReducer;
