import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Badge,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import { useParams ,useLocation,useHistory} from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios';
import { server_url } from "constants/types";
import { inspectionSummaryListGetAll } from '../../actions/inspectionsummaryAction';
import { VISITORINOUT_CLEAR_SNACKBAR ,INSPECTION_SUMMARY_SEARCH,INSPECTION_SUMMARY_ERROR} from 'constants/types';
import OtpInput from 'react-otp-input';

const InspectionExport = ({ isOpen, onClose, onExport }) => {
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  
  
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [otp, setOtp] = useState('');

//   Get Otp function
const getOtpFunction = () => {
    setLoading(true);
    const body = {
        "name" : "Inspection"
    }
    axios
      .post(`${server_url}/admin/download/report/send-otp`,body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
        {
            setData(response.data.data);
            toastFunction(response.data.message, 'success');
        }
        else
        {
            toastFunction(response.data.message, 'error');
        }
      }).catch(() => console.log("error"))

      setLoading(false);
  }

//   Verify OTP
  const verifyOtpFunction = (session) => {
    setLoading(true);
    const body = {
        "otp" : otp
    }
    axios
      .post(`${server_url}/admin/download/report/verify-otp/${session}`,body, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((response) => {
        if(response.data.status == 'OK')
        {
            setData("");
            toastFunction(response.data.message, 'success');
            onClose();
            onExport();
        }
        else
        {
            toastFunction(response.data.message, 'error');
        }
      }).catch(() => console.log("error"))

      setLoading(false);
  }

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  return (
  <>

    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader>Download Report</ModalHeader>
        <ModalCloseButton />
        {data == "" ? <ModalBody>
        <Text>Generate an OTP to download report. It shall be sent to registered email and phone. This action shall be audited.</Text>
        </ModalBody> : <ModalBody pb={6}>
            <OtpInput
            inputStyle={{ width: '2.5rem', height: '2.5rem', margin: '0 0.5rem', textAlign: 'center' ,border: '1px solid gray', // Add border style here
              borderRadius: '4px', // Optional: round the corners
              fontSize: '1.5rem', }}
            focusStyle={{ borderColor: 'blue', outline: 'none' }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
              <Text mt="8px" fontSize="12px" color="green">An OTP has been sent to the mobile number {data?.maskedContactNo}.</Text>
            </ModalBody>}
        <ModalFooter>
        {loading ? <Spinner mt='24px' size='lg' />:data == "" ?<Button onClick={()=>
            {
                getOtpFunction();
            }
        } colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>Get OTP</Button>:<Button onClick={()=>
            {
                verifyOtpFunction(data?.session);
            }
        } colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>Verify</Button>}
        </ModalFooter>
    </ModalContent>
    </Modal>
  </>
  );
};

export default InspectionExport;
