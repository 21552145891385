import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Center,Spinner,Image,Box} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { CANTEENINOUT_CLEAR_SNACKBAR ,CANTEENINOUT_SEARCH} from 'constants/types';

import CanteenHeadcountTableRow from './CanteenHeadcountTableRow';
import { CanteenInOutGetAll } from '../../../actions/canteenInOutAction';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import RefreshIcon from "assets/img/refreshicon.png";
import RefreshGif from "assets/img/refresh.gif";

const CanteeenHeadcountTable = () => {
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.canteenInOut.status);
  const responseMessage = useSelector(state => state.canteenInOut.text);
  const loading = useSelector(state => state.canteenInOut.loading);
  const canteenInOutList = useSelector(state => state.canteenInOut.canteenInOutList);
  const response = useSelector(state => state.canteenInOut.response);
  const showSnackbar = useSelector(state => state.canteenInOut.showSnackbar);
  const currentPage = useSelector(state => state.canteenInOut.page);
  const totalItems = useSelector(state => state.canteenInOut.totalItems);
  const limit = useSelector(state => state.canteenInOut.limit);
  const searching = useSelector(state => state.canteenInOut.searching);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);

  // lazy loading
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [newCanteenData, setNewCanteenData] = useState([]);
  const [isNewData, setIsNewData] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    
    setIsNewData(true);
    dispatch(CanteenInOutGetAll(`&headcount=true&type=IN&page=${page}&limit=50`));
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : '',
      }
    })
  }, []);
  console.log("canteenInOutList------")
  console.log(canteenInOutList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CANTEENINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column+"&headcount=true&type=IN&limit=50";
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(CanteenInOutGetAll(sorting));
  };

  const handelSearch = (search) => {
    const searching = "&search=" + search+"&headcount=true&type=IN&limit=50";
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(CanteenInOutGetAll(searching));
    setNewCanteenData([]);
    setPage(1);
  }

  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Canteeenheadcount_"+Date.now();
    // Define the headers
    const headers = [
      "Supplier", "Dept. / Sub-Dept.", "IN Time & Date", "Driver Details","Total Head Count"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      { header: 'Supplier', key: 'supplierName', width: 15 },
      { header: 'Dept. / Sub-Dept.', key: 'department', width: 20 },
      { header: 'IN Time & Date', key: 'inTime', width: 20 },
      { header: 'Driver Details ', key: 'driverDetail', width: 20 },
      { header: 'Total Head Count', key: 'totalHeadcount', width: 20 },

    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of newCanteenData.entries()) {
      const rowData = [
        row.supplierName,
        row.department,
        row.subdepartment,
        row.inDate,
        row.inTime,
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(colIndex === 0)
          {
            sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.supplierName}`;
          }
        else if(colIndex === 1)
          {
            sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.department?.name} \n ${row.subdepartment?.name}`;
          }
          else if(colIndex === 2)
          {
              sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.inDate} \n ${row.inTime}`;
          }
          else if(colIndex === 3)
          {
            
              sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.driverName } \n ${row.mobileNo}`;
          }
          else if(colIndex === 4)
          {
            
            sheet.getCell(rowIndex + 2, colIndex + 1).value = "1";
          }
          else
          {
              sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.cell}`;
          }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };

   // lazy loading 
   const handleScroll = (e) => {
    
    const bottomReached = (e.target.scrollHeight - e.target.scrollTop <= (parseInt(e.target.clientHeight) + 1)) &&  (e.target.scrollHeight - e.target.scrollTop >= (e.target.clientHeight -1));
    if(!loading)
      {
        console.log("->>>>> this ->>>>>>" + bottomReached);
        console.log("->>>>> this ->>>>>>" + (e.target.clientHeight + 1) );
        console.log("->>>>> this ->>>>>>" + e.target.scrollHeight );
        console.log("->>>>> this ->>>>>>" + e.target.scrollTop );
        console.log("->>>>> match ->>>>>>" + (e.target.scrollHeight-e.target.scrollTop) );
        if (bottomReached && !isFetching && newCanteenData.length < totalItems && canteenInOutList.length != 0) {
          setPage(prevPage => prevPage + 1);
          setIsFetching(true); // Prevent multiple fetches at the same time
          // Load next page
        }
      }
  };

  useEffect(() => {
    if (isFetching) {
      dispatch(CanteenInOutGetAll(`&headcount=true&type=IN&page=${page}&limit=50${searching}&search=${search}`));
      setIsFetching(false);
    }
  }, [isFetching,page])
  useEffect(() => {
    addVisitorDataArray(canteenInOutList);
    const currentDate = new Date();
    var splitCurrentDate = currentDate.toString().split(' GMT')[0]
    setCurrentDateTime(splitCurrentDate.toString());
  }, [canteenInOutList])
  useEffect(() => {
    setNewCanteenData([]);
  }, [searching,isNewData])
  // Function to add new data
const addVisitorDataArray = (newDataArray) => {
  setNewCanteenData((prevData) => [...prevData,...newDataArray]);
};

  return (
  <>
  {/* <CurrentAdvanceSearch></CurrentAdvanceSearch> */}
  <Flex direction="column" pt={{ base: "120px", md: "5px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <Flex>
            <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
                Canteen Driver
              </Text>
              
              
            <Flex color='white'>
            <Text fontSize="sm" color={textColor}>
              Last Updated : {currentDateTime}
              </Text>
              {!loading ?
              <Button size='xs' colorScheme="blue" ml="4px" onClick={() =>
              {
                setNewCanteenData([]);
                dispatch(CanteenInOutGetAll(`&headcount=true&type=IN&page=${page}&limit=50`));
              }
              } >
              Refresh
            </Button>
            : <Spinner mt="4px" ml="4px" size="xs" color='black'/>
            }
            </Flex>
            </CardHeader>
            
            <Spacer/>
            <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
              {/* <DownloadTableExcel
                filename="Visitor Data"
                sheet="users"
                CanteeenHeadcountTableRef={tableRef.current}
              > */}
                <Button
                isLoading={submittingLoader}
                loadingText='Downloading'
                 onClick={exportToExcel}
                colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                  Export Excel
                </Button>
              {/* </DownloadTableExcel> */}
            </Flex>

            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius="8px" w="200px">
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      ></IconButton>
                    }
                  />
                  <Input
                    variant="search"
                    fontSize="xs"
                    bg={inputBg}
                    onKeyUp={(e) => {
                      setSearch(e.target.value);
                      handelSearch(e.target.value);
                      if (e.target.value.length < 3) {
                        setSearch("");
                        handelSearch("");
                      }
                    }}
                    placeholder="Type here..."
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
          <CardBody pb={4}>
            <div className='vertical-scroll' // Set a fixed height for the scroll container
        onScroll={handleScroll}>
              
              <Table variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("vechilenumber")}
                    cursor="pointer"
                  >
                    Supplier
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("createdAt")}
                    cursor="pointer"
                  >
                    Dept. / Sub-Dept.
                    {sortColumn === "createdAt" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("drivername")}
                    cursor="pointer"
                  >
                    In Time
                    {sortColumn === "drivername" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("drivername")}
                    cursor="pointer"
                  >
                    Driver Details
                    {sortColumn === "drivername" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("intime")}
                    cursor="pointer"
                  >
                    Total Head Count
                    {sortColumn === "intime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                  </Th>

                </Tr>
              </Thead>
                <Tbody>
                  {newCanteenData ? (newCanteenData.map((row, index, arr) => {

                    return (
                      <CanteenHeadcountTableRow
                      serialnumber={totalItems - index}
                      supplier = {row.supplierName}
                      department = {row.department?.name}
                      subdepartment = {row.subdepartment?.name}
                      inDate = {row.inDate}
                      inTime = {row.inTime}
                      contactNo = {row.mobileNo}
                      name = {row.driverName}
                      id = {row._id}
                      isLast={index === arr.length - 1 ? true : false}

                      />
                    );
                  })) : (<h1></h1>)}
                </Tbody>
              </Table>
              {loading ? <Center><Spinner mt="24px" size="lg" /></Center>:<></>}
            </div>
          </CardBody>
        </Card>
    </Flex>
  </>
  );
};

export default CanteeenHeadcountTable;
