import React, { useEffect, useState ,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link , Button  } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon } from '@chakra-ui/icons';
import { CANTEENINOUT_CLEAR_SNACKBAR  ,CANTEENINOUT_SEARCH } from 'constants/types';

import CanteenInOutTableRow from './CanteenInOutTableRow';
import { CanteenInOutGetAll } from 'actions/canteenInOutAction';
import CanteenInOutPagination from './CanteenInOutPagination';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CanteenInOutAdvanceSearch from './CanteenInOutAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const CanteenInOutTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.canteenInOut.status);
  const responseMessage = useSelector(state => state.canteenInOut.text);
  const loading = useSelector(state => state.canteenInOut.loading);
  const CanteenInOutList = useSelector(state => state.canteenInOut.canteenInOutList);
  const response = useSelector(state => state.canteenInOut.response);
  const showSnackbar = useSelector(state => state.canteenInOut.showSnackbar);
  const currentPage = useSelector(state => state.canteenInOut.page);
  const limit = useSelector(state => state.canteenInOut.limit);
  const totalItems = useSelector(state => state.canteenInOut.totalItems);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  const [submittingLoader, setSubmittingLoader] = useState(false);
  useEffect(() => {
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll());
  }, [dispatch]);
  console.log("CanteenInOutList")
  console.log(CanteenInOutList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: CANTEENINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : CANTEENINOUT_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(CanteenInOutGetAll(searching));
  }, [search]);
  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Canteen InOut Data');

    // Define the headers
    const headers = [
        'SNO', 'Date','Supplier Name', 'Invoice No.', 'Item',
        'Quantity (UOM)', 'Invoice Image'
    ];

    // Set up the columns with specific widths
    sheet.columns = [
        { header: 'SNO', key: 'sno', width: 10 },
        { header: 'Date', key: 'date', width: 20 },
        { header: 'Supplier Name', key: 'supplierName', width: 20 },
        { header: 'Invoice No.', key: 'invoiceNo', width: 20 },
        { header: 'Material', key: 'canteenCategory', width: 25 },
        { header: 'Quantity', key: 'quantityUOM', width: 20 },
        { header: 'Invoice Image', key: 'invoiceImage', width: 30 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
        sheet.getCell(1, index + 1).value = header;
    });

    // Add data with rowspan logic
    let snoCounter = 1; // Start serial number from 1
    let currentRowIndex = 2; // Start from the second row for data

    for (const entry of CanteenInOutList) { // Assuming `data` contains the array you provided
        const { date, canteenItems, supplierName } = entry; // Extract relevant fields
        sheet.getCell(currentRowIndex, 1).value = snoCounter++; // SNO
        sheet.getCell(currentRowIndex, 2).value = date;
        sheet.getCell(currentRowIndex, 3).value = supplierName;
        // Process each canteenItem
        for (const canteenItem of canteenItems) {
            const { invoiceNo, invoiceImage, items ,invoiceImageUrl} = canteenItem;
            const itemCount = items.length;

            // First row for this invoice
             // Date
            sheet.getCell(currentRowIndex, 4).value = invoiceNo; // Invoice No.
            if(invoiceImageUrl != "" && invoiceImageUrl != null)
            {
              const base64Image =invoiceImageUrl;
              const imageId = workbook.addImage({
                base64: base64Image,
                extension: 'jpeg' // Change if your image is PNG
              });
              
              sheet.getCell(currentRowIndex, 5).value = sheet.addImage(imageId, {
                tl: { col: 6, row: currentRowIndex-1 }, // Top-left corner
                ext: { width: 60, height: 60 } // Resize image
              }); // Invoice Image
            }
            else
            {
              sheet.getCell(currentRowIndex, 5).value = ""; // Invoice Image
            }

            // Merge cells for rowspan (SNO, Date, Invoice No., Delivery Agent)
            if (itemCount > 1) {
                sheet.mergeCells(currentRowIndex, 1, currentRowIndex + itemCount - 1, 1); // SNO
                sheet.mergeCells(currentRowIndex, 2, currentRowIndex + itemCount - 1, 2); // Date
                sheet.mergeCells(currentRowIndex, 3, currentRowIndex + itemCount - 1, 3); // supplier Name
                sheet.mergeCells(currentRowIndex, 4, currentRowIndex + itemCount - 1, 4); // Invoice No.
                sheet.mergeCells(currentRowIndex, 6, currentRowIndex + itemCount - 1, 6); // Invoice Image
            }

            // Add each item
            for (const item of items) {
                // Fill item details in the correct rows
               
                    // Only fill the first item's canteen category and quantity
                    sheet.getCell(currentRowIndex, 5).value = item.canteenCategoryName; // Canteen Category
                    sheet.getCell(currentRowIndex, 6).value = `${item.quantity} ${item.uom}`; // Quantity (UOM)

                currentRowIndex++; // Move to the next row
            }
        }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
        sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `Canteen InOut Data.xlsx`);
    setSubmittingLoader(false);
};



  return (
    <>
    <CanteenInOutAdvanceSearch></CanteenInOutAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Canteens
            </Text>
          </CardHeader>
          <Spacer />


          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Canteen Data"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              isLoading={submittingLoader}
              loadingText='Downloading'
               onClick={exportToExcel}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Export Excel
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
        <div className='vertical-scroll'>
          {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table  variant="simple" color={textColor} ref={tableRef}>
            <Thead className='stick-header'>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("updatedAt")}
                  cursor="pointer"
                >
                  Date
                  {sortColumn === "updatedAt" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("updatedAt")}
                  cursor="pointer"
                >
                  Supplier Name
                  {sortColumn === "updatedAt" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("updatedAt")}
                  cursor="pointer"
                >
                  Invoice
                  {sortColumn === "updatedAt" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("updatedAt")}
                  cursor="pointer"
                >
                  Item
                  {sortColumn === "updatedAt" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Quantity
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
              Invoice
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {CanteenInOutList ? (CanteenInOutList.map((row, index, arr) => {
                
                return (
                  <CanteenInOutTableRow
                //  serialnumber={((currentPage - 1)*limit) + (index+1)}
                 serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                    date={row.date}
                    materialBy={row.materialBy}
                    supplierName={row.supplierName}
                    canteenItems={row.canteenItems}
                    typeOfItems={row.canteenCategoryName}
                    deliveryAgentName={row.driverName}
                    driverPhoto={row.image}
                    checkpostInName={row.inCheckpostName ? row.inCheckpostName : "N/A"}
                    checkpostInTime={row.checkpostInTime === "Invalid date"  ?  "N/A" : row.checkpostInTime}
                    checkpostInGuardName={row.guard  ? row.guard.name : "N/A"}

                    checkpostOutName={row.outCheckpostName ? row.outCheckpostName : "N/A"}
                    checkpostOutTime={row.checkpostOutTime === "Invalid date"  ?  "N/A" : row.checkpostOutTime}
                    checkpostOutGuardName={row.outGuard  ? row.outGuard.name : "N/A"}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                  />
                );
              })) : (<h1></h1>)}
            </Tbody>
          </Table>}
        </div>
          {CanteenInOutList.length === 0 ? (
            <></>
          ) : (
            <CanteenInOutPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(CanteenInOutGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default CanteenInOutTable;
